import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
/**路由重复报错*/
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const routes = [
  {
    title: "首页",
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/",
    redirect: "/home",
    hidden: true,
  },
  {
    title: "技术",
    path: "/home#core-technology",
    name: "Technology",
    component: () => import("@/views/Home.vue"),
  },
  {
    title: "产品",
    path: "/home#product",
    name: "Product",
    component: () => import("@/views/Home.vue"),
  },
  {
    hidden: true,
    title: "chainSQL",
    path: "/product/chainSQL",
    name: "Product-ChainSQL",
    component: () => import("@/views/ProductPages/ChainSQL.vue"),
  },
  {
    hidden: true,
    title: "众享Fabric产品",
    path: "/product/fabric",
    name: "Product-Fabric",
    component: () => import("@/views/ProductPages/Fabric.vue"),
  },
  {
    hidden: true,
    title: "众享联盟链",
    path: "/product/league",
    name: "Product-League",
    component: () => import("@/views/ProductPages/League.vue"),
  },
  {
    hidden: true,
    title: "ChainSQL功能调用平台",
    path: "/product/functionAdjustment",
    name: "Product-FunctionAdjustment",
    component: () => import("@/views/ProductPages/FunctionAdjustment.vue"),
  },
  {
    hidden: true,
    title: "WisChain区块链应用平台",
    path: "/product/wisChain",
    name: "Product-WisChain",
    component: () => import("@/views/ProductPages/WisChain.vue"),
  },
  {
    hidden: true,
    title: "众享区块链超级工作站",
    path: "/product/superworkstation",
    name: "Product-Superworkstation",
    component: () => import("@/views/ProductPages/Superworkstation.vue"),
  },
  {
    hidden: true,
    title: "众享区块链供应链金融平台",
    path: "/product/finance",
    name: "Product-Finance",
    component: () => import("@/views/ProductPages/Finance.vue"),
  },
  {
    hidden: true,
    title: "众享区块链存证平台",
    path: "/product/certificate",
    name: "Product-Certificate",
    component: () => import("@/views/ProductPages/Certificate.vue"),
  },
  {
    hidden: true,
    title: "众享区块链数据共享平台",
    path: "/product/share",
    name: "Product-Share",
    component: () => import("@/views/ProductPages/Share.vue"),
  },
  {
    hidden: true,
    title: "众享区块链溯源平台",
    path: "/product/traceability",
    name: "Product-Traceability",
    component: () => import("@/views/ProductPages/Traceability.vue"),
  },
  {
    hidden: true,
    title: "众享区块链积分平台",
    path: "/product/point",
    name: "Product-Point",
    component: () => import("@/views/ProductPages/Point.vue"),
  },
  {
    hidden: true,
    title: "众享区块浏览器",
    path: "/product/browser",
    name: "Product-Browser",
    component: () => import("@/views/ProductPages/Browser.vue"),
  },
  {
    hidden: true,
    title: "众享区块链监控平台",
    path: "/product/monitor",
    name: "Product-Monitor",
    component: () => import("@/views/ProductPages/Monitor.vue"),
  },
  {
    hidden: true,
    title: "众享联盟链治理平台",
    path: "/product/governance",
    name: "Product-Governance",
    component: () => import("@/views/ProductPages/Governance.vue"),
  },
  // {
  //   title: "应用案例",
  //   path: "/case",
  //   name: "Case",
  //   component: () => import("@/views/Case.vue"),
  // },
  {
    title: "解决方案",
    path: "/solution",
    name: "Solution",
    component: () => import("@/views/Solution.vue"),
    redirect: "/solution/supplyChain",
    children: [
      {
        title: "供应链金融+区块链",
        path: "/solution/supplyChain",
        name: "solution-supplyChain",
        component: () => import("@/views/SolutionPages/SupplyChain.vue"),
      },
      {
        title: "溯源+区块链",
        path: "/solution/traceability",
        name: "solution-traceability",
        component: () => import("@/views/SolutionPages/Traceability.vue"),
      },
      {
        title: "数据共享+区块链",
        path: "/solution/share",
        name: "solution-share",
        component: () => import("@/views/SolutionPages/Share.vue"),
      },
      {
        title: "存证+区块链",
        path: "/solution/certificate",
        name: "solution-certificate",
        component: () => import("@/views/SolutionPages/Certificate.vue"),
      },
      {
        title: "线上交易平台+区块链",
        path: "/solution/transaction",
        name: "solution-transaction",
        component: () => import("@/views/SolutionPages/Transaction.vue"),
      },
      {
        title: "司法+区块链",
        path: "/solution/justice",
        name: "solution-justice",
        component: () => import("@/views/SolutionPages/Justice.vue"),
      },
      {
        title: "清结算+区块链",
        path: "/solution/settlement",
        name: "solutionsettlement",
        component: () => import("@/views/SolutionPages/Settlement.vue"),
      },
      {
        title: "资产证券化+区块链",
        path: "/solution/assetSecuritization",
        name: "solution-assetSecuritization",
        component: () => import("@/views/SolutionPages/AssetSecuritization.vue"),
      },
      {
        title: "扶贫+区块链",
        path: "/solution/poverty",
        name: "solution-poverty",
        component: () => import("@/views/SolutionPages/Poverty.vue"),
      },
      {
        title: "积分商城+区块链",
        path: "/solution/point",
        name: "solution-point",
        component: () => import("@/views/SolutionPages/Point.vue"),
      },
    ],
  },
  {
    title: "新闻资讯",
    path: "/news",
    name: "News",
    component: () => import("@/views/News.vue"),
  },
  {
    title: "关于我们",
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
  {
    title: "加入",
    path: "/join-us",
    name: "JoinUs",
    component: () => import("@/views/JoinUs.vue"),
  },
  {
    hidden:true,
    title: "职位详情",
    path: "/join-us/detail",
    name: "joinUs-detail",
    component: () => import("@/views/JoinUsDetail.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
